//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'GroupSettings',
    created() {
        this.load();
    },
    data() {
        return {
            name: null,
            privacy: 'Public',
            openDeleteGroupModal: false,
            isPwd: true,
            password: null,
            isLoading: true
        }
    },
    methods: {
        save() {
            if (this.name == '') {
                this.$q.notify({
                    message: 'The group name cannot be empty.',
                    color: 'warning'
                });
                return;
            }

            this.$http({
                url: 'groups/' + this.$route.params.id + '/settings',
                method: 'PUT',
                data: {
                    name: this.name,
                    privacy: this.privacy
                }
            })
            .then((response) => {
                this.isLoading = false;
                switch (response.data['status']) {
                    default: 
                        this.$q.notify({
                            message: 'The group settings was successfully saved.',
                            color: 'positive',
                            icon: 'save'
                        });
                    break;
                }
            })
            .catch((err) => {
                this.isLoading = false;
                switch (err.response.status) {
                    case 404:
                        this.$router.push({
                            name: 'NotFound404'
                        });
                    break;
                    case 403:
                        this.$router.push({
                            name: 'NotFound404'
                        });
                    break;
                    case 500:
                        this.$router.push({
                            name: 'NotFound404'
                        });
                    break;
                }
            });            
        },
        trash() {
            this.$http({
                url: 'groups/' + this.$route.params.id,
                method: 'DELETE'
            }).then((response) => {
                this.isLoading = false;
                switch (response.data['status']) {
                    case 'group_deleted':
                        this.$router.push({
                            name: 'EntityDeleted',
                            query: {entity_type: 'group'}
                        });
                    break;
                }
            }).catch((err) => {
                this.isLoading = false;
                switch (err.response.status) {
                    case 404:
                        this.$router.push({
                            name: 'NotFound404'
                        });
                    break;
                    case 403:
                        this.$router.push({
                            name: 'NotFound404'
                        });
                    break;
                    case 500:
                        this.$router.push({
                            name: 'NotFound404'
                        });
                    break;
                }
            });
        },
        load() {
            this.$http({
                url: 'groups/' + this.$route.params.id + '/settings',
                method: 'GET'
            }).then((response) => {
                this.isLoading = false;
                switch (response.data['status']) {
                    case 'group_settings_retrieved':
                        this.name = response.data['body']['group']['name'],
                        this.privacy = response.data['body']['group']['privacy']
                    break;
                }
            }).catch((err) => {
                this.isLoading = false;
                switch (err.response.status) {
                    case 404:
                        this.$router.push({
                            name: 'NotFound404'
                        });
                    break;
                    case 403:
                        this.$router.push({
                            name: 'NotFound404'
                        });
                    break;
                    case 500:
                        this.$router.push({
                            name: 'NotFound404'
                        });
                    break;
                }
            });
        }
    }
}
