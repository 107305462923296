import { render, staticRenderFns } from "./GroupSettings.vue?vue&type=template&id=bd735550&"
import script from "./GroupSettings.vue?vue&type=script&lang=js&"
export * from "./GroupSettings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QSpinnerGears,QCard,QCardSection,QInput,QList,QItem,QItemSection,QRadio,QItemLabel,QCardActions,QBtn,Ripple,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QSpinnerGears,QCard,QCardSection,QInput,QList,QItem,QItemSection,QRadio,QItemLabel,QCardActions,QBtn})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple,ClosePopup})
